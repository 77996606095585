.wm-line-hdr-row,
.wm-line-row {
  display: flex;
}
.wm-line-value {
  flex: 1;
}
.wm-line-value-wide {
  flex: 4;
}
