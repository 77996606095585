.search-input {
  height: 100px;
}

.left-side {
  display: flex;
  flex-direction: column;
}

.submit-btn {
  margin-top: 15px;
  width: 100px;
}
