.clickable-link {
  cursor: pointer;
}

.clickable-link-container {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  background-color: aliceblue;
  padding: 10px;
  margin-top: 0;
  width: 100%;
  margin-bottom: 20px;
}

.input-group {
  z-index: 1;
}
