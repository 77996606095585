.event-card {
  width: 100%;
  margin-bottom: 15px;
}

.header-name {
  font-weight: bold;
  margin-left: 2rem;
}

.table { 
  margin-bottom: 0;
}

.centered {
  align-content: center;
}

.link {
  color: black;
}

.link:hover {
  color: black;
}
